.zonas-box {
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.zonas-content {
    width: 100%;
    padding: var(--padding-md);
}

.zonas-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.zonas-section__zona {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
    color: var(--color-text-primario);
    padding: var(--padding-md);
}

.zonas-section__secciones {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--color-text-primario);
    padding: var(--padding-md);
    display: grid;
    align-items: center;
    gap: 10px;
    user-select: none;
    grid-template-columns: repeat(auto-fill, minmax(min(var(--size-xl), 100%), 1fr));
}

.zonas-section__seccion {
    width: 100%;
    height: var(--size-lg);
    display: grid;
    place-content: center;
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--color-border);
    cursor: pointer;
}

.zonas-section__seccion:hover,
.zonas-section__seccion--seleccionada {
    border-color: var(--color-background-elements-active);
}

.zonas-section__asientos-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--padding-md);
    gap: 10px;
}

.zonas-section__asientos {
    width: 100%;
    display: flex;
    /* align-items: center; */
    gap: 10px;
}

.zonas-section__asientos-fila {
    width: var(--size-md);
    height: 100%;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--color-text-primario);
    padding: calc(var(--padding-sm) + 2px) 0;
    border-right: 2px solid var(--color-border);
    display: grid;
    place-content: center;
}

.zonas-section__asientos-row {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

.zonas-section__asientos-asiento {
    width: 28px;
    height: 28px;
    font-size: var(--font-size-2xs);
    font-weight: var(--font-weight-medium);
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--color-border);
    color: var(--color-text);
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zonas-section__asientos-asiento--ocupado {
    color: var(--color-negativo);
    border-color: var(--color-negativo);
}


.content-svg-estadio {
    width: 100%;
    max-width: 700px;
    margin: 0px auto;
}

.mapa-estadio {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 10px;
}

.mapa-estadio__zonas-box {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mapa-estadio__zona-card {
    width: 100%;
    border-radius: 7px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 2px 4px 2px #4646462f;
    cursor: pointer;
    border: 1px solid transparent;
    position: relative;
}

.mapa-estadio__zona-card:hover {
    border-color: var(--bColor-zona);
}

/* attr(data-count); */
.mapa-estadio__zona-titulo {
    font-size: 20px;
    font-weight: 400;
    color: #182029;
    padding: 5px;
}

.mapa-estadio__zona-precio {
    font-size: 16px;
    padding: 5px;
}

.mapa-estadio__zona-detalle {
    font-size: 16px;
    padding: 5px;
    color: #969696;
}

.mapa-estadio__zona-content {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.mapa-estadio__secciones-hidden {
    max-height: 0;

    overflow: hidden;
}

.mapa-estadio__secciones {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(80px, 100%), 1fr));
    gap: 10px;
    padding: 5px;
}

.mapa-estadio__seccion {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 2px #4646462f;
}

.mapa-estadio__seccion:hover {
    border-color: var(--bColor-seccion);
}

.mapa-estadio__seccion-titulo {
    font-size: 16px;
    font-weight: 400;
    color: #182029;
    padding: 3px;
}

.mapa-estadio__seccion-detalle {
    font-size: 12px;
    padding: 3px;
    color: #969696;
}

.st0 {
    fill: #3c860a;
}

.st1 {
    fill: #FFFFFF;
    stroke: #FFFFFF;
    stroke-width: 0.330;
    stroke-miterlimit: 8.5295;
}

.st2 {
    fill: none;
    stroke: #020203;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
}

.st3 {
    fill: #020203;
}

.st4 {
    fill: #CCCCCC;
    cursor: pointer;
}

.pasarela-pagos {
    width: 100%;
    max-width: 700px;
    margin: auto;
}

.pasarela-pagos__header {
    width: 100%;
    height: max-content;
    min-height: 70px;
    text-align: left;
    /* border-bottom: 1px solid var(--color-border); */
    border-bottom: 1px solid var(--color-border);
    padding: var(--padding-md);
}

.pasarela-pagos__total {
    width: 100%;
    color: var(--color-text-primario);
    font-size: var(--font-size-2xl);
    font-weight: bolder;
}

.pasarela-pagos__detalle {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: var(--padding-md);
    border-bottom: 1px solid var(--color-border);
}

.pasarela-pagos_metodos {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: var(--padding-md);
}

.pasarela-pagos_metodos-item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: var(--padding-md);
    color: var(--color-text-hard);
    cursor: pointer;
    border-bottom: 1px solid var(--color-border);
}

.pasarela-pagos__footer {
    width: 100%;
    padding: var(--padding-md);
    border-top: 1px solid var(--color-border);
}


/* QR */

.qr__box {
    width: 100%;
    display: grid;
    place-content: center;
    padding: var(--padding-md);
}

.qr__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: var(--padding-md);
}

.qr__preview {
    width: 100%;
    max-width: 1200px;
    min-width: 200px;
    aspect-ratio: 1/1;
    display: grid;
    place-content: center;
    padding: var(--padding-md);
    border-radius: var(--border-radius-sm);
    background-color: var(--color-background-elements);
}